import React from "react";

const UsersIcon: React.FC = () => <>
  <svg width="50" height="55" viewBox="0 0 47 51" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.95029 47.5773C5.95029 47.5773 9.57378 42.8816 8.97763 36.3192C8.38147 29.7568 1.07422 26.248 1.07422 16.4236C1.07422 6.59931 16.1268 0.647642 23.4366 1.79769" stroke="white" stroke-width="2" stroke-linecap="round"/>
  <path d="M23.2031 1.76562C27.7995 1.99136 36.6518 6.92928 38.0136 11.0771" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M38.2119 11.6328C38.2119 11.6328 39.869 14.9887 38.9491 18.1279C38.0291 21.267 44.7122 26.4844 45.5949 27.4543C46.4777 28.4241 41.6539 29.6682 41.6539 30.3664C41.6539 31.0646 42.6138 33.5729 42.6138 34.2398C42.6138 34.9068 41.6539 37.2118 41.6539 37.8246C41.6539 38.4373 44.0555 39.4055 42.6138 42.3743C41.1721 45.3432 34.2217 43.6843 31.2399 45.3432C28.2581 47.0022 27.1406 49.6574 27.1406 49.6574" stroke="white" stroke-width="2" stroke-linecap="round"/>
  <path d="M27.6797 22.2656C27.6797 22.2656 29.4574 24.0076 31.235 24.0076C33.0127 24.0076 34.7904 22.2656 34.7904 22.2656" stroke="white" stroke-width="2" stroke-linecap="round"/>
  <path d="M17.1572 23.1355C13.8978 17.9956 9.98667 20.3172 9.31404 23.3292C8.64141 26.3412 11.1968 24.9892 12.4408 29.6383C13.6848 34.2874 18.2105 31.0744 18.2105 31.0744" stroke="white" stroke-width="2" stroke-linecap="round"/>
  <path d="M18.076 8.07812C16.7247 8.07812 15.6289 9.15674 15.6289 10.4875C15.6289 11.5617 16.0572 14.1112 20.2726 16.6885C20.3481 16.7342 20.4348 16.7584 20.5232 16.7584C20.6116 16.7584 20.6983 16.7342 20.7738 16.6885C24.9892 14.1112 25.4175 11.5617 25.4175 10.4875C25.4175 9.15674 24.3216 8.07812 22.9703 8.07812C21.619 8.07812 20.5232 9.53834 20.5232 9.53834C20.5232 9.53834 19.4274 8.07812 18.076 8.07812Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</>

export default UsersIcon;
