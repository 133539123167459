import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";

import { mod } from "../utils";
import { Base, LimitMaxWidth, SectionHeading, urls } from "../components/layout";
import HeroCtaSection from "../components/page-sections/HeroCtaSection";
import SEO from "../components/seo";
import InnovationIcon from "../assets/svg/icon-value-innovation";
import DiversityIcon from "../assets/svg/icon-value-diversity";
import CollaborationIcon from "../assets/svg/icon-value-collaboration";
import UsersIcon from "../assets/svg/icon-value-users";
import CarouselSelectedIcon from "../assets/svg/icon-carousel-selected";
import CarouselNotSelectedIcon from "../assets/svg/icon-carousel-not-selected";
import heroWebp from "../assets/images/hero-careers.webp";
import introPng from "../assets/images/intro-careers.png";
import introAvif from "../assets/images/intro-careers.avif";
import introWebp from "../assets/images/intro-careers.webp";
import lifePng from "../assets/images/careers-life.png";
import lifeAvif from "../assets/images/careers-life.avif";
import lifeWebp from "../assets/images/careers-life.webp";
import flexiblePng from "../assets/images/benefits-flexible.png";
import servicesPng from "../assets/images/benefits-services.png";
import birthdayPng from "../assets/images/benefits-birthday.png";
import flexibleAvif from "../assets/images/benefits-flexible.avif";
import servicesAvif from "../assets/images/benefits-services.avif";
import birthdayAvif from "../assets/images/benefits-birthday.avif";
import flexibleWebp from "../assets/images/benefits-flexible.webp";
import servicesWebp from "../assets/images/benefits-services.webp";
import birthdayWebp from "../assets/images/benefits-birthday.webp";
import bannerPng from "../assets/images/banner-join.png";
import bannerAvif from "../assets/images/banner-join.avif";
import bannerWebp from "../assets/images/banner-join.webp";

export const Head = () => <SEO title="Syrona Health | Security" />

const CareersPage: React.FC = () => <Base><CareersContent /></Base>

export default CareersPage;

const ctaAction = () => {
  location.href = "https://www.linkedin.com/company/syronahealth/jobs";
}

const CareersContent: React.FC = () => <>
  <HeroCtaSection 
    image={heroWebp}
    heading={<>Creating Health Equity</>}
    subheading="for every life stage."
    text="Syrona is building the future of life-stage healthcare. We believe this will be personalised, affordable and accessible."
    cta="View careers"
    ctaAction={ctaAction}
  />
  <IntroSection/>
  <ValuesSection/>
  <LifeSection/>
  <DiversityAndInclusionSection/>
  <BenefitsSection/>
  <JoinSection/>
</>

const IntroSection: React.FC = () => <>
  <div className="pt-12">
    <LimitMaxWidth>
      <SectionHeading>
      "At Syrona, we want to build, learn and grow with people who are mission-driven and want to create a legacy of change."
      </SectionHeading>
      <div className="mt-6 flex justify-center">
        <div className="max-sm:text-m-b sm:text-s2 font-nun text-black">
        {"\u2014 Anya & Chantelle (founders)"}
        </div>
      </div>
      <div className="flex justify-center">
        <picture className="w-8/12 mt-8">
          <source srcSet={introAvif} type="image/avif" />
          <source srcSet={introWebp} type="image/webp" />
          <img src={introPng} alt="Anya & Chantelle"/>
        </picture>
      </div>
    </LimitMaxWidth>
  </div>
</>

const values = [
  {
    title: "Innovation is our DNA",
    text: "We are committed to continual improvement of our products. There's always more to learn.",
    icon: InnovationIcon,
  },
  {
    title: "We embrace diversity",
    text: "Syrona was created by founders of colour to rectify gender health gaps. Accessibility to all is at our core.",
    icon: DiversityIcon,
  },
  {
    title: "Collaboration matters",
    text: "We regularly partner with charities and research organisations to maximise our innovation and learn from others.",
    icon: CollaborationIcon,
  },
  {
    title: "Do right by our users",
    text: "User privacy and experience is our top priority. With Syrona, you will always be in control of your data.",
    icon: UsersIcon,
  },
]

const ValuesSection: React.FC = () => {
  const [selected, setSelected] = useState(0);
  const [rotationTimeout, setRotationTimeout] = useState<NodeJS.Timeout|null>(null);
  const updateSelectedTimeout = 8 * 1000;

  useEffect(() => {
    rotationTimeout && clearTimeout(rotationTimeout)
    const timeout = setTimeout(() => setSelected(mod(selected+1, values.length)), updateSelectedTimeout);
    setRotationTimeout(timeout)
  }, [selected])

  return <>
    <div className="py-12 bg-gradient-to-r from-syr-main to-syr-snd">
      <LimitMaxWidth>
        <h3 className="max-sm:text-m-lg-t sm:text-h3 font-dm text-white text-center">
          Syrona's Core Values
        </h3>
        <div className="max-sm:text-m-b sm:text-b1 font-nun text-white text-center">
          Syrona's mission is to democratise access to healthcare for every life stage
        </div>
        <div className="pt-8 md:hidden max-md:flex md:h-0 justify-center items-center">
            <div className="max-sm:h-60 sm:h-72 w-72 flex flex-col justify-start items-center px-8">
              {values[selected].icon({})}
              <h4 className="mt-4 max-sm:text-m-t2 sm:text-s1 font-nunb text-white text-center">
                {values[selected].title}
              </h4>
              <p className="max-sm:text-m-b sm:text-b1 font-nun text-white text-center">
                {values[selected].text}
              </p>
            </div>
        </div>
        <div className="md:hidden flex justify-center space-x-4">
          {values.map((_, idx) => <>
            <button onClick={() => setSelected(idx)}>
              {idx==selected ? <CarouselSelectedIcon/> : <CarouselNotSelectedIcon/>}
            </button>
          </>)}
        </div>
        <div className="pt-8 max-md:hidden flex justify-center">
          <div className="grid content-start justify-items-center lg:grid-cols-4 md:max-lg:grid-cols-2 gap-8">
            {values.map(v => <>
              <div className="w-72 flex flex-col justify-start items-center">
                <v.icon/>
                <h4 className="mt-4 text-s1 font-nunb text-white text-center">{v.title}</h4>
                <p className="text-m-b font-nun text-white text-center">{v.text}</p>
              </div>
            </>)}
          </div>
        </div>
      </LimitMaxWidth>
    </div>
  </>
}

const LifeSection: React.FC = () => <>
  <div className="py-12">
    <LimitMaxWidth>
      <SectionHeading>
        Life at Syrona
      </SectionHeading>
      <div className="flex justify-center">
        <p className="max-w-3xl mx-8 text-b1 font-nun text-black text-center">
          No two days are the same - seriously. We are a flexible, fun and empathetic bunch. We promise a flat structure and want our employees to succeed and enjoy their job!
        </p>
      </div>
      <picture className="p-12 object-scale-down">
        <source srcSet={lifeAvif} type="image/avif" />
        <source srcSet={lifeWebp} type="image/webp" />
        <img src={lifePng} alt="Life at Syrona" />
      </picture>
    </LimitMaxWidth>
  </div>
</>

const cultureMissionVision = [
  {
    title: "Culture",
    body: <>Our diverse team are not only smart but empathetic, respectful and driven.</>,
  },
  {
    title: "Mission",
    body: <>We create accessible care and contributing to research, all to close the <b>#genderhealthgap</b>.</>,
  },
  {
    title: "Vision",
    body: <>Our vision is to be a trusted healthcare provider for underserved health journeys.</>,
  },
]

const DiversityAndInclusionSection: React.FC = () => <>
  <div className="py-12 bg-syr-white-smoke">
    <LimitMaxWidth>
      <SectionHeading>
        Diversity & Inclusion
      </SectionHeading>
      <div className="flex max-md:flex-col justify-center items-center md:space-x-8 max-md:space-y-8 overflow-x-hidden p-12">
        {cultureMissionVision.map(cmv => <>
          <div className="flex-none w-80 h-48 bg-white rounded-lg shadow-sym-2xl flex flex-col p-8">
            <h3 className="text-s2 font-nun text-syr-snd uppercase">{cmv.title}</h3>
            <p className="text-b2 font-nun text-black">{cmv.body}</p>
          </div>
        </>)}
      </div>
    </LimitMaxWidth>
  </div>
</>

const benefits = [
  {
    title: "Flexible working",
    body: <>Our employees enjoy unparalleled flexibility to work remote or hybrid.</>,
    avif: flexibleAvif,
    webp: flexibleWebp,
    png: flexiblePng,
  },
  {
    title: "Access to SORA",
    body: <>Our employees can access life-stage healthcare at their fingertips.</>,
    avif: servicesAvif,
    webp: servicesWebp,
    png: servicesPng,
  },
  {
    title: "Paid birthday leave",
    body: <>On their special day, our employees are entitled to a paid birthday leave.</>,
    avif: birthdayAvif,
    webp: birthdayWebp,
    png: birthdayPng,
  },
]

const BenefitsSection: React.FC = () => {
  const [selected, setSelected] = useState(0);
  
  return <>
    <div className="py-12 object-center bg-gradient-to-r from-syr-main to-syr-snd">
      <LimitMaxWidth>
        <SectionHeading>
          <span className="text-white">No BS. Just Benefits that work for you</span>
        </SectionHeading>
        <div className="md:hidden mt-8 max-md:flex justify-center items-center">
            <div className="w-96 h-96 flex flex-col justify-center items-center space-y-4 pb-8">
              <picture>
                <source srcSet={benefits[selected].avif} type="image/avif" />
                <source srcSet={benefits[selected].webp} type="image/webp" />
                <img className="h-48 object-scale-down" src={benefits[selected].png} alt={`${benefits[selected].title}`} />
              </picture>
              <div className="max-sm:text-m-t2 sm:text-h4 font-dm text-white text-center">{benefits[selected].title}</div>
              <div className="max-sm:text-m-b sm:text-s2 font-nun text-white text-center">{benefits[selected].body}</div>
            </div>
        </div>
        <div className="md:hidden flex justify-center space-x-4">
          {benefits.map((_, idx) => <>
            <button onClick={() => setSelected(idx)}>
              {idx==selected ? <CarouselSelectedIcon/> : <CarouselNotSelectedIcon/>}
            </button>
          </>)}
        </div>
        <div className="max-md:hidden mt-8 md:flex mx-8 content-start justify-center items-start space-x-24">
          {benefits.map(b => <>
            <div className="flex flex-col justify-center items-center space-y-4 w-96">
              <picture>
                <source srcSet={b.avif} type="image/avif" />
                <source srcSet={b.webp} type="image/webp" />
                <img className="h-48 object-scale-down" src={b.png} alt={`${b.title}`}/>
              </picture>
              <div className="text-h4 font-dm text-white text-center">{b.title}</div>
              <div className="text-s2 font-nun text-white text-center">{b.body}</div>
            </div>
          </>)}
        </div>
      </LimitMaxWidth>
    </div>
  </>
}

const JoinSection: React.FC = () => <>
  <div className="py-12">
    <LimitMaxWidth>
      <div className="mx-8 flex max-md:flex-col justify-center">
        <picture className="md:w-5/12 max-md:order-1 max-md:pt-12">
          <source srcSet={bannerAvif} type="image/avif" />
          <source srcSet={bannerWebp} type="image/webp" />
          <img src={bannerPng} alt="Join us"/>
        </picture>
        <div className="md:w-7/12 max-md:order-0 flex flex-col justify-center">
          <h3 className="text-h3 font-dm text-black">Want to join our team?</h3>
          <p className="text-b1 font-nun text-black">
            If you think you're a good fit for our company but can't find a job post applicable to you, we'd love to hear from you. Send us your CV!
          </p>
          <Link to={urls.contactUs}>
            <button className="mt-8 w-48 h-16 bg-syr-snd rounded-md text-btn font-nun text-white">Email Us</button>
          </Link>
        </div>
      </div>
    </LimitMaxWidth>
  </div>
</>
