import React from "react";

const InnovationIcon: React.FC = () => <>
  <svg width="40" height="55" viewBox="0 0 40 55" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.78516 6.15625H31.951" stroke="white" stroke-width="2" stroke-linecap="round"/>
  <path d="M2.28906 48.1562H32.4549" stroke="white" stroke-width="2" stroke-linecap="round"/>
  <path d="M4.30078 15.1562H29.439" stroke="white" stroke-width="2" stroke-linecap="round"/>
  <path d="M4.30078 39.1562H29.439" stroke="white" stroke-width="2" stroke-linecap="round"/>
  <path d="M1.78395 1.65625C1.1136 5.98958 1.8845 16.3562 10.3309 23.1562" stroke="white" stroke-width="2" stroke-linecap="round"/>
  <path d="M21.9684 30.3253C25.9931 32.7558 33.7244 40.7247 32.4521 53.1562" stroke="white" stroke-width="2" stroke-linecap="round"/>
  <path d="M31.951 1.65625C32.7889 6.98958 31.3476 19.3562 18.8791 26.1562C3.29345 34.6562 1.78516 42.6562 1.78516 53.1562" stroke="white" stroke-width="2" stroke-linecap="round"/>
  </svg>
</>

export default InnovationIcon;
